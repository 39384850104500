import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Logo from '../components/logo'

class Gallery extends React.Component {

  state = {
    slideIndex: 0,
    slideCount: 0
  }

  componentDidMount() {
    this.setTimer()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  setTimer() {
    clearTimeout(this.timer)
    const delay = this.props.slides[this.state.slideIndex] ? this.props.slides[this.state.slideIndex].delay * 1000 : 250;
    this.timer = setTimeout(this.handleTimer, delay)
  }

  handleTimer = () => {

    if ((this.state.slideCount === this.props.slides.length) && (this.state.slideIndex === this.props.slides.length - 1)) {
      this.props.fadeOutSplash()
    } else {
      this.setState(prev => {
        if (prev.slideIndex >= this.state.slideCount - 1) {
          return {
            slideIndex: 0
          }
        } else {
          return {
            slideIndex: prev.slideIndex + 1
          }
        }
      })
      this.setTimer()
    }
  }

  countSlides = () => {
    this.setState(prev => {
      return {
        slideCount: prev.slideCount + 1
      }
    })
  }

  render() {

    const { slides, show } = this.props

    return (
      <div className="pabs fs">
        {slides && slides.map((slide, i) => {
          return (
            <Slide show={show} slide={slide} count={i} counter={this.countSlides} {...this.state} key={`slide-${i}`} />
          )
        })}
      </div>
    )
  }
}

class Slide extends React.Component {

  state = {
    ready: false
  }

  componentDidMount() {
    this.loadImage(this.props.slide.image.fluid.srcSet)
  }

  componentWillUnmount() {
    this.destroyImage()
  }

  loadImage(srcset) {
    this.img = new Image()
    this.img.srcset = srcset
    this.img.onload = e => {
      // console.log(this.props.slide)
      this.renderSlide()
    }
  }

  destroyImage() {
    if (!this.img) {
      return;
    }
    this.img.onload = function(){};
    delete this.img;
  }

  renderSlide() {
    this.setState({
      ready: true
    })
    this.props.counter()
  }

  render() {

    const { show, count, slideIndex, slide } = this.props

    return this.state.ready ? (
      <div className="gallery-slide fs pabs t0 l0" style={{
        zIndex: !show ? 1 : count === slideIndex ? 100 : 1
      }}>
        <img sizes={slide.image.fluid.sizes} srcSet={slide.image.fluid.srcSet} src={slide.image.fluid.src} alt={slide.image.title} />
      </div>
    ) : null
  }
}

class SplashContent extends React.Component {

  state = {
    show: true,
    slides: null
  }

  componentDidMount() {
    this.refs.el.addEventListener('transitionend', this.props.removeSplash)

    window.addEventListener('resize', this.handleResize)

    this.setSlides()

    // this.timer = setTimeout(this.fadeOutSplash, 5000)
  }

  componentWillUnmount() {
    this.refs.el.removeEventListener('transitionend', this.props.removeSplash, false)
    window.removeEventListener('resize', this.handleResize, false)
    // clearTimeout(this.timer)
  }

  setSlides() {
    this.setState({
      slides: window.innerWidth > window.innerHeight ? this.props.splashPageGallery : this.props.splashPageGalleryMobile
    })
  }

  fadeOutSplash = () => {
    this.setState({
      show: false
    })
  }

  handleResize = () => {
    //Resize Window
    const onResize = () => {
      this.setSlides()
    };
    var doit;
    doit = setTimeout(onResize, 400);
    window.onresize = () => {
      clearTimeout(doit);
      doit = setTimeout(onResize, 400);
    };
  }

  render() {

    return (
      <div className="splash-page" onClick={this.fadeOutSplash} onTouchStart={this.fadeOutSplash} ref="el" style={{
        opacity: this.state.show ? 1 : 0,
        transition: '0.75s'
      }}>
        {!this.state.slides || <Gallery show={this.state.show} slides={this.state.slides} fadeOutSplash={this.fadeOutSplash} />}
        <div className="pabs fs flex-center z-top">
          <div className="splash-logo">
            <Logo fill="#FFFFFF" />
          </div>
        </div>
      </div>
    )
  }
}

const SplashPage = (props) => (
  <StaticQuery query={splashQuery} render={data => (
    <SplashContent {...data.allContentfulSettings.edges[0].node} {...props} />
  )} />
)

export default SplashPage

const splashQuery = graphql`
  query settingsSplashQuery {
    allContentfulSettings (
      limit: 1
    ) {
      edges {
        node {
          splashPageGallery {
            delay
            image {
              fluid(maxWidth: 1200, quality: 90) {
                srcSet,
                sizes,
                aspectRatio
              }
            }
          }
          splashPageGalleryMobile {
            delay
            image {
              fluid(maxWidth: 768, quality: 90) {
                srcSet,
                sizes,
                src,
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`
