import React from 'react'

class Picture extends React.Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    const img = this.refs.img;
    if (img && img.complete) {
      this.onLoad()
    }
  }

  onLoad = e => {
    if (!this.state.loaded) {
      this.setState({
        loaded: true
      })
    }
  }

  render() {

    const { className, style, src, srcSet, aspectRatio, sizes, force, alt } = this.props

    const imgStyle = !this.state.loaded && !force ? {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      transition: 'opacity 0.5s'
    } : {
      opacity: 1,
      transition: 'opacity 0.5s'
    }

    return (
      <picture className={className} style={{
        position: 'relative',
        ...style
      }}>
        {!this.state.loaded && !force ? (
          <svg width={1000 * aspectRatio} height={1000} viewBox={`0 0 ${1000 * aspectRatio} 1000`}>
            <rect width={1000 * aspectRatio} height={1000} fill={!srcSet && !src ? '#FF0000' : '#F2F2F2'} />
          </svg>
        ) : null}
        {srcSet ? (
          <img sizes={sizes} srcSet={srcSet} src={src} alt={alt} ref="img" onLoad={this.onLoad} style={imgStyle} />
        ) : src ? (
          <img src={src} alt={alt} ref="img" onLoad={this.onLoad} style={imgStyle} />
        ) : null}
      </picture>
    )
  }
}

Picture.defaultProps = {
  className: '',
  style: {},
  src: '',
  srcSet: '',
  sizes: '',
  alt: '',
  aspectRatio: 1
}

export default Picture
