import React from 'react'
import moment from 'moment-timezone'
import { StaticQuery, graphql } from 'gatsby'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import Picture from '../components/picture'

let SCROLL = 0;

class Instagram extends React.Component {

  state = {
    open: this.props.path === '/',
    isScrolling: false,
    isTouch: false,
    limit: 90
  }

  toggle = () => {
    this.setState(last => {
      return {
        open: !last.open
      }
    })
  }

  componentDidMount() {
    this.setState({
      open: this.props.path === '/',
      limit: window.innerWidth < 768 ? 90 : 130
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.path !== this.props.path) {
      this.setState({
        open: this.props.path === '/'
      })
    }
  }

  onTouch = () => {

    if (!this.state.isTouch) {
      this.setState({
        isTouch: true
      })
      clearAllBodyScrollLocks();
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleMouseEnter = () => {
    if (!this.state.isTouch) {
      disableBodyScroll(this.refs.feed)
    }
  }

  handleMouseLeave = () => {
    if (!this.state.isTouch) {
      enableBodyScroll(this.refs.feed)
    }
  }

  excerpt = (s) => {
    return s ? `${s.substring(0,this.state.limit)}…` : s
  }

  handleMouseWheel = e => {
    if (!this.state.isTouch) {
      if (e.deltaX) {
        SCROLL = this.refs.feed.scrollLeft
      } else if (e.deltaY) {
        SCROLL = SCROLL + e.deltaY
        this.refs.feed.scrollTo(SCROLL, 0)
      }

      if (!this.state.isScrolling && SCROLL > 0) {
        this.setState({
          isScrolling: true
        })
      } else if (this.state.isScrolling && SCROLL <= 0) {
        this.setState({
          isScrolling: false
        })
      }
    }
  }

  render() {

    const posts = this.props.allInstagramPhoto.edges

    return (
      <div
        className={`instagram-wrapper ${this.state.open ? 'open' : ''} ${this.state.isScrolling ? 'scrolling' : ''}`}
        onWheel={this.handleMouseWheel}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onTouchStart={this.onTouch}
        ref={this.props.innerRef}
        style={{
          position: this.props.showFooter ? 'absolute' : 'fixed',
          bottom: 0,
        }}
      >
        <div className="h3 upcase instagram-header">
          <div className="w100 inner bdr-top">
            <span className="point" onClick={this.toggle}>
              <span className="toggle-icon" style={{
                transform: this.state.open ? `rotate(45deg) scale(1.3)` : `rotate(0deg) scale(1)`
              }}>+</span>
              <span className="toggle-label">Instagram</span>
            </span>
          </div>
        </div>
        <div className="instagram-feed" ref="feed">
          {posts.map(({node}, i) => {
            const caption = this.excerpt(node.edge_media_to_caption.edges[0] && node.edge_media_to_caption.edges[0].node.text)
            const time = node.taken_at_timestamp ? moment(node.taken_at_timestamp * 1000).fromNow() : ''
            return (
              <a className="instagram-post" href={`https://instagram.com/p/${node.shortcode}`} target="_blank" rel="noopener noreferrer" key={node.shortcode}>
                <figure>
                  <Picture src={node.thumbnail_src} />
                  <figcaption>
                    <p className="mb p2">{caption}</p>
                    <h3 className="h4 timeago">{time}</h3>
                  </figcaption>
                </figure>
              </a>
            )
          })}
        </div>
      </div>
    )
  }
}

const Content = (props) => (
  <StaticQuery
    query={instaQuery}
    render={data => <Instagram {...data} {...props} />}
  />
)

export default Content

const instaQuery = graphql`
  query instaQuery {
    allInstagramPhoto {
      edges {
        node {
          shortcode
  				display_url
          thumbnail_src
          thumbnail_resources {
            src
            config_width
            config_height
          }
          taken_at_timestamp
          edge_media_to_caption {
            edges {
              node {
                text
              }
            }
          }
        }
      }
    }
  }
`
