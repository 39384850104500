import React from 'react'
import logoSrc from '../images/logo.png'

const Footer = () => (
  <footer>
    <span>
      <span className="logo-img inlb mr"><img src={logoSrc} alt={'Corporate Purple PR logo'} /></span>
      <span className="copyright">© Purple 2019</span>
    </span>
    <span className="footer-credits">
      <span className="inlb mr">Design: <a href="//anyother.name" target="_blank" rel="noopener noreferrer">Any Other Name</a></span>
      <span className="inlb">Build: <a href="//official.business" target="_blank" rel="noopener noreferrer">Official Business</a></span>
    </span>
  </footer>
)

export default Footer
