import React from 'react'

const Logo = ({fill}) => (
  <svg width="288px" height="48px" viewBox="0 0 288 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" fill={fill ? fill : '#000000'} fillRule="evenodd">
      <path d="M22.43,0 C29.12,0 33.43,0.9 36.37,3 C39.06,4.94 40.37,8 40.37,12.46 C40.37,17.46 38.65,20.74 35.61,22.67 C32.57,24.6 28.61,25.23 23.33,25.23 L11.33,25.23 L11.33,42 C11.33,44.55 11.88,45.1 14.09,45.1 L20.85,45.1 L20.85,46.83 L0,46.83 L0,45.06 L5.93,45.06 C8.14,45.06 8.7,44.51 8.7,41.96 L8.7,4.76 C8.7,2.35 8.28,1.76 5.93,1.76 L0,1.76 L0,0 L22.43,0 Z M11.32,23.53 L22.7,23.53 C27.12,23.53 30.64,23.05 33.26,21.53 C35.88,20.01 37.61,17.18 37.61,12.63 C37.61,7.93 35.95,5.11 32.71,3.52 C30.23,2.28 26.78,1.73 22.22,1.73 L11.32,1.73 L11.32,23.53 Z"></path>
      <path d="M84.63,31.81 L84.63,4.81 C84.63,2.4 84.22,1.81 81.87,1.81 L76,1.81 L76,0 L95,0 L95,1.73 L89.53,1.73 C87.05,1.73 86.7,2.35 86.7,4.73 L86.7,31.81 C86.7,42.37 81.87,48 69.8,48 C58.27,48 53.44,42.48 53.44,32.4 L53.44,4.76 C53.44,2.35 53.1,1.76 50.61,1.76 L45.09,1.76 L45.09,0 L64.76,0 L64.76,1.73 L58.89,1.73 C56.41,1.73 56.06,2.35 56.06,4.73 L56.06,31.81 C56.06,41.34 60,46.17 70.21,46.17 C80.7,46.17 84.63,41.34 84.63,31.81 Z"></path>
      <path d="M124.69,0 C129.69,0 133.31,0.69 135.94,2.14 C139.32,4 140.94,7.04 140.94,11.25 C140.94,18.84 136.39,22.25 129.49,22.77 L129.49,22.91 C136.11,24.08 137.77,27.74 137.77,34.09 L137.77,41.54 C137.77,45.27 138.87,46.17 140.67,46.17 C142.88,46.17 143.84,44.86 143.84,40.03 L143.84,34.5 L145.5,34.5 L145.5,40.58 C145.5,45.48 144.39,47.89 140.32,47.89 C136.25,47.89 135.15,45.82 135.15,41.06 L135.15,34.5 C135.15,30.09 134.25,27.4 132.39,25.81 C130.53,24.22 127.56,23.67 123.39,23.67 L111.23,23.67 L111.23,42 C111.23,44.55 111.78,45.1 113.99,45.1 L120.82,45.1 L120.82,46.83 L99.91,46.83 L99.91,45.06 L105.85,45.06 C108.05,45.06 108.61,44.51 108.61,41.96 L108.61,4.76 C108.61,2.35 108.2,1.76 105.85,1.76 L99.91,1.76 L99.91,0 L124.69,0 Z M111.23,21.88 L124.48,21.88 C129.31,21.88 133.38,21.18 135.8,18.63 C137.32,17.05 138.15,14.77 138.15,11.38 C138.15,7.8 136.9,5.45 134.83,4 C132.42,2.28 128.9,1.73 124.48,1.73 L111.23,1.73 L111.23,21.88 Z"></path>
      <path d="M174.13,0 C180.82,0 185.13,0.9 188.07,3 C190.76,4.94 192.07,8 192.07,12.46 C192.07,17.46 190.35,20.74 187.31,22.67 C184.27,24.6 180.31,25.23 175.02,25.23 L163,25.23 L163,42 C163,44.55 163.55,45.1 165.76,45.1 L172.52,45.1 L172.52,46.83 L151.7,46.83 L151.7,45.06 L157.63,45.06 C159.84,45.06 160.39,44.51 160.39,41.96 L160.39,4.76 C160.39,2.35 159.98,1.76 157.63,1.76 L151.7,1.76 L151.7,0 L174.13,0 Z M163,23.53 L174.4,23.53 C178.82,23.53 182.34,23.05 184.96,21.53 C187.58,20.01 189.31,17.18 189.31,12.63 C189.31,7.93 187.65,5.11 184.41,3.52 C181.92,2.28 178.47,1.73 173.92,1.73 L163,1.73 L163,23.53 Z"></path>
      <path d="M225.29,45.06 C227.479835,45.0816133 229.669348,44.9914962 231.85,44.79 C236.2,44.44 236.61,40.44 236.61,35.13 L236.61,27.81 L238.4,27.81 L238.4,46.81 L197.2,46.81 L197.2,45.06 L203.14,45.06 C205.35,45.06 205.9,44.51 205.9,41.96 L205.9,4.76 C205.9,2.35 205.49,1.76 203.14,1.76 L197.2,1.76 L197.2,0 L217.42,0 L217.42,1.73 L211.28,1.73 C208.94,1.73 208.52,2.35 208.52,4.73 L208.52,45.03 L225.29,45.06 Z"></path>
      <path d="M244.36,45.06 L250.3,45.06 C252.5,45.06 253.06,44.51 253.06,41.96 L253.06,4.76 C253.06,2.35 252.65,1.76 250.3,1.76 L244.36,1.76 L244.36,0 L287.77,0 L287.77,16.91 L286,16.91 L286,13.91 C286,2.73 285.86,1.77 274.75,1.77 L255.68,1.77 L255.68,21.6 L268.45,21.6 C272.18,21.6 273,20.6 273,15.66 L273,11.59 L274.73,11.59 L274.73,34 L273,34 L273,29.81 C273,25.67 272.66,23.33 268.45,23.33 L255.68,23.33 L255.68,45.06 L274.73,45.06 C285.84,45.06 285.98,44.06 285.98,32.85 L285.98,28.5 L287.77,28.5 L287.77,46.79 L244.36,46.79 L244.36,45.06 Z"></path>
    </g>
  </svg>
)

export default Logo
