import React from 'react'
import PropTypes from 'prop-types'
import '../css/app.scss'
// import Header from '../components/header'
import Instagram from '../components/instagram'
import Footer from '../components/footer'
import Splash from '../components/splash'

class Layout extends React.Component {

  state = {
    showSplash: true,
    showFooter: 0,
    instagramHeight: 54
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    let { bottom } = this.refs.position.getBoundingClientRect();

    if (!this.state.showFooter && bottom < window.innerHeight) {
      // console.log(this.refs.position.offsetHeight)
      this.setState({
        showFooter: true,
        instagramHeight: this.instagramEL.offsetHeight
      })
    } else if (this.state.showFooter && bottom >= window.innerHeight) {
      this.setState({
        showFooter: false,
        instagramHeight: this.instagramEL.offsetHeight
      })
    }
  }

  removeSplash = () => {
    this.setState({
      showSplash: false
    })
  }

  render() {

    const { children, path } = this.props

    return (
      <>
        {!this.state.showSplash || <Splash removeSplash={this.removeSplash} />}
        <main ref="position">

          {children}
          <Instagram path={path} innerRef={o => this.instagramEL = o} {...this.state} />
        </main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
